var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M4.17675 3.19848C3.90315 2.92914 3.46461 2.93461 3.19725 3.2107C2.92989 3.48679 2.93496 3.92894 3.20856 4.19827L7.07053 8L3.20856 11.8017C2.93496 12.0711 2.92989 12.5132 3.19725 12.7893C3.46461 13.0654 3.90315 13.0709 4.17675 12.8015L8.06245 8.97644L11.8233 12.6786C12.0969 12.9479 12.5354 12.9424 12.8027 12.6664C13.0701 12.3903 13.065 11.9481 12.7914 11.6788L9.05436 8L12.7914 4.32122C13.065 4.05188 13.0701 3.60973 12.8027 3.33364C12.5354 3.05755 12.0969 3.05208 11.8233 3.32142L8.06245 7.02356L4.17675 3.19848Z",
          fill: "#A7ADB6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }